import * as React from "react"
import '../css/App.css';
import { Link } from "gatsby";
import { useEffect, useState } from 'react';
const moment = require('moment');

// markup
const BreadTimerPage = () => {

    const calculateTimeLeft = (targetHour) => {
        const tomorrowTarget = moment().add(1, 'day').startOf('day').hours(targetHour);
        const difference = +tomorrowTarget - +new Date();
        let timeLeft = {};

        // @todo: handle when it's less than 5 hours until 7AM.

        if (difference > 0) {
            timeLeft = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                // Round minutes to the nearest 10 minutes.
                minutes: Math.floor((difference / 1000 / 60) % 60 - (difference / 1000 / 60) % 10),
            };
        }

        return timeLeft;
    }

    const [targetHour, setTargetHour] = useState(7);
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
    useEffect(() => {
        const timer=setTimeout(() => {
          setTimeLeft(calculateTimeLeft(targetHour));
        }, 30000);
        // Clear timeout if the component is unmounted.
        return () => clearTimeout(timer);
      });

      useEffect(() => {
        setTimeLeft(calculateTimeLeft(targetHour));
      });

      const timerComponents = <span>{timeLeft.hours}:{timeLeft.minutes}</span>

  return (
    <div>
        <div className="text-center">
        <h1 className="fa-center text-5xl text-center mb-8 mt-4 border-b-4 border-indigo-700 inline-block">Bread timer</h1>
      </div>
      <div className="text-center">
          <p className="font-mono text-4xl text-indigo-700">{targetHour}:00 AM</p>
          <p className="text-lg mb-4">
           Target time
          </p>
          <p className="font-mono text-4xl text-indigo-700">{timerComponents}</p>
          <p className="text-lg mb-4">
           Programme time
          </p>
          </div>

          <div className="text-center">
        <footer className="App-footer mt-8">
           <Link to="/" className="rounded-md py-3 px-6 bg-white hover:bg-indigo-50 border border-indigo-500 hover:border-indigo-800 shadow-md">Home</Link>
          </footer>
          </div>
      </div>
  )
}

export default BreadTimerPage
